<template>
  <div>
    <page-heading :heading="$t(modelId ? 'edit': 'create')">
      <template v-slot:actions>
        <app-button
          :disabled="loading"
          :label="$t(modelId ? 'update' : 'create')"
          :loading="loading"
          @click="submit"
        />
      </template>

      <!--
      <template v-slot:tabs>
        <tabs
          :tabs="tabs"
          :activeTab="activeTab"
          @switchTab="switchTab"
        />
      </template>
      -->
    </page-heading>

    <loading-wrapper>
      <form-open
        @submit="submit"
        autocomplete="off"
        class="max-w-2xl"
      >
        <div class="grid grid-cols-2 gap-4">
          <template v-if="activeTab === 'basic'">
            <form-text
              :can-copy="true"
              :form-id="formId"
              :label="$t('model.user.username')"
              class="col-span-full"
              form-error-id="username"
              input-id="username"
              v-model="model.username"
              v-if="modelId"
            />
            <form-text
              :form-id="formId"
              :label="$t('model.user.first_name')"
              form-error-id="first_name"
              input-id="first_name"
              v-model="model.first_name"
            />
            <form-text
              :form-id="formId"
              :label="$t('model.user.last_name')"
              form-error-id="last_name"
              input-id="last_name"
              v-model="model.last_name"
            />
            <form-text
              :form-id="formId"
              :label="$t('model.user.email')"
              :required="false"
              form-error-id="email"
              icon="inbox"
              input-id="email"
              v-model="model.email"
            />
            <form-text
              :form-id="formId"
              :label="$t('model.user.ref')"
              :required="false"
              form-error-id="ref"
              input-id="ref"
              v-model="model.ref"
            />
            <form-select
              :form-id="formId"
              :label="$t('model.user.status')"
              :options="data.statuses"
              class="col-span-full"
              form-error-id="status"
              input-id="status"
              v-if="modelId"
              v-model="model.status"
            />
            <div class="col-span-full">
              <a href="#" class="flex items-center text-gray-600 link" @click.prevent="togglePasswordFields">
                <app-svg class="h-5 w-5" :svg="!showPasswordFields ? 'lock-closed' : 'lock-open'"/>
                <span class="ml-2 text-sm">{{ $t(!showPasswordFields ? 'update' : 'cancel') }}</span>
              </a>
            </div>

            <div class="col-span-full">
              <div class="grid grid-cols-2 gap-4">
                <form-text
                  :can-copy="true"
                  :disabled="!showPasswordFields"
                  :form-id="formId"
                  :label="$t('model.user.password')"
                  form-error-id="password"
                  input-id="password"
                  type="password"
                  v-model="model.password"
                />
                <form-text
                  :disabled="!showPasswordFields"
                  :form-id="formId"
                  :label="$t('model.user.password_confirmation')"
                  form-error-id="password_confirmation"
                  input-id="password_confirmation"
                  type="password"
                  v-model="model.password_confirmation"
                />
              </div>
            </div>
            <form-select
              :form-id="formId"
              :help="$t('roles_help_text')"
              :label="$t('model.user.roles')"
              :multiple="true"
              :options="data.roles"
              :required="false"
              class="col-span-full"
              input-id="roles"
              v-model="selectedRoles"
              v-if="data.roles.length"
            />
            <form-select
              :form-id="formId"
              :label="$t('model.user.permissions')"
              :multiple="true"
              :options="data.permissions"
              :required="false"
              class="col-span-full"
              form-error-id="permissions"
              input-id="permissions"
              v-model="model.permissions"
            />
          </template>
        </div>
        <button class="hidden"/>
      </form-open>
    </loading-wrapper>
  </div>
</template>

<script>
import ApiUserRoleService from '@/services/api/user_role';
import ApiUserService from '@/services/api/user';
import ApiUserStatusService from '@/services/api/user_status';
import ApiPermissionService from '@/services/api/permission';
import Common from '@/mixins/common';
import _ from 'lodash';

export default {
  data() {
    return {
      activeTab: 'basic',
      formId: 'createEditUser',
      data: {
        statuses: [],
        rolePermissions: [],
        roles: [],
        permissions: [],
      },
      modelId: this.$route.params.userId,
      model: {
        permissions: [],
      },
      selectedRoles: [],
      showPasswordFields: false,
      tabs: [
        {
          id: 'basic',
          // icon: 'cube',
          label: this.$t('basic'),
        },
      ],
    };
  },
  methods: {
    submit() {
      if (this.modelId) {
        ApiUserService.updateOrganisationUser(this.modelId, this.model, {
          formId: this.formId,
          showMessage: true,
        })
          .catch(() => {});
      } else {
        ApiUserService.createOrganisationUser(this.model, {
          formId: this.formId,
          showMessage: true,
        })
          .then((response) => {
            const { data } = response.data;

            this.modelId = data.id;
            this.$router.push({
              name: 'users.edit',
              params: {
                userId: data.id,
              },
            });
          })
          .catch(() => {});
      }
    },
    switchTab(tabId) {
      this.activeTab = tabId;
    },
    togglePasswordFields() {
      this.showPasswordFields = !this.showPasswordFields;

      if (!this.showPasswordFields) {
        this.model.password = null;
        this.model.password_confirmation = null;
      }
    },
  },
  mounted() {
    ApiUserStatusService.getStatuses()
      .then((response) => {
        const { data } = response.data;

        this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label) => `user_status.${label}`);
      })
      .catch(() => {});

    ApiPermissionService.getOrganisationPermissions()
      .then((response) => {
        const { data } = response.data;

        this.data.permissions = this.toSelectOptions(data, 'name', 'id', (label, value) => `permissions.${value}.label`);
      })
      .catch(() => {});

    ApiUserRoleService.getOrganisationUserRoles({
      params: {
        include: [
          'permissions',
        ],
      },
    })
      .then((response) => {
        const { data } = response.data;

        this.data.roles = this.toSelectOptions(data, 'name', 'id');

        const rolePermissions = [];
        _.forEach(data, (role) => {
          rolePermissions[role.id] = _.map(role.permissions, 'id');
        });
        this.data.rolePermissions = rolePermissions;
      })
      .catch(() => {});

    if (this.modelId) {
      ApiUserService
        .getOrganisationUser(this.modelId, {
          params: {
            include: [
              'permissions',
            ],
          },
        })
        .then((response) => {
          const { data } = response.data;

          this.model = _.pick(data, [
            'email',
            'first_name',
            'last_name',
            'permissions',
            'ref',
            'status',
            'username',
          ]);
        })
        .catch(() => {});
    }
  },
  mixins: [
    Common,
  ],
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedRoles(newRoles, oldRoles) {
      let rolePermissions = [];
      _.forEach(newRoles, (selectedRole) => {
        rolePermissions = _.union(rolePermissions, this.data.rolePermissions[selectedRole]);
      });

      this.model.permissions = rolePermissions;
    },
  },
};
</script>
